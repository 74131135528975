import {ApolloClient, from, InMemoryCache, split} from '@apollo/client';
import {ErrorResponse, onError} from '@apollo/client/link/error';
import {removeTypenameFromVariables} from '@apollo/client/link/remove-typename';
import {getMainDefinition} from '@apollo/client/utilities';
import createUploadLink from 'apollo-upload-client/createUploadLink.mjs';
import merge from 'deepmerge';
import isEqual from 'lodash/isEqual';
import {useMemo} from 'react';
import {FormattedMessage} from 'react-intl';
import {Dispatch} from 'redux';
import fragment from '../../node_modules/@eon.cz/apollo13-graphql-web/lib/fragmentTypesV3.json';
import {BackendEndpoints} from '../../server/BackendEndpoints';
import {GraphQLErrorResolver} from '../common/components/GraphQLErrorResolver';
import {NotificationsActionCreator} from '../common/components/notifications/actions/NotificationsActions';
import {NotificationType} from '../common/components/notifications/model/NotificationModel';
import {StoreType} from './StoreType';

declare const process: any;

const removeTypenameLink = removeTypenameFromVariables();

const errorLink = (dispatch: Dispatch) =>
    onError(({graphQLErrors, networkError}: ErrorResponse) => {
        if (typeof graphQLErrors === 'object' && graphQLErrors.length > 0) {
            GraphQLErrorResolver.resolveErrors(dispatch, graphQLErrors);
            // If there are GraphQL errors, ignore network
        } else {
            if (networkError) {
                const {addNotification} = NotificationsActionCreator(dispatch);
                addNotification({
                    type: NotificationType.ERROR,
                    text: <FormattedMessage id="error.network" />,
                });
            }
        }
    });

const createClient = (initialState: StoreType | null, dispatch: Dispatch) => {
    const httpLink: any = createUploadLink({
        uri: `/api/${BackendEndpoints.GRAPHQL}`,
        fetchOptions: {credentials: 'same-origin'},
        headers: {
            'X-Apollo-Operation-Name': 'graphql',
        },
    });

    const link = process.browser
        ? split(({query}) => {
              const definition = getMainDefinition(query);
              return definition.kind === 'OperationDefinition' && (definition.operation === 'query' || definition.operation === 'mutation');
          }, httpLink)
        : httpLink;

    return new ApolloClient({
        connectToDevTools: process.browser,
        ssrMode: !process.browser,
        assumeImmutableResults: false,
        link: from([errorLink(dispatch), removeTypenameLink, link]),
        cache: new InMemoryCache({
            possibleTypes: fragment.possibleTypes,
            typePolicies: {
                Query: {
                    fields: {
                        adresniMista: {
                            merge: true,
                        },
                        sopWeb: {
                            merge: true,
                        },
                    },
                },
            },
        }).restore(initialState || {}),
        defaultOptions: {
            query: {
                fetchPolicy: 'no-cache',
            },
            watchQuery: {
                nextFetchPolicy(lastFetchPolicy) {
                    if (lastFetchPolicy === 'cache-and-network' || lastFetchPolicy === 'no-cache') {
                        return 'cache-first';
                    }
                    return lastFetchPolicy;
                },
            },
        },
    });
};

export let apolloClient: ApolloClient<any>;

export const initApollo = (initialState: StoreType | null, dispatch: Dispatch) => {
    const _apolloClient = apolloClient ?? createClient(initialState, dispatch);

    // If your page has Next.js data fetching methods that use Apollo Client, the initial state
    // gets hydrated here
    if (initialState) {
        // Get existing cache, loaded during client side data fetching
        const existingCache = _apolloClient.extract();

        // Merge the existing cache into data passed from getStaticProps/getServerSideProps
        const data = merge(initialState, existingCache, {
            // combine arrays using object equality (like in sets)
            arrayMerge: (destinationArray, sourceArray) => [...sourceArray, ...destinationArray.filter((d) => sourceArray.every((s) => !isEqual(d, s)))],
        });

        // Restore the cache with the merged data
        _apolloClient.cache.restore(data);
    }
    // For SSG and SSR always create a new Apollo Client
    if (typeof window === 'undefined') return _apolloClient;
    // Create the Apollo Client once in the client
    if (!apolloClient) apolloClient = _apolloClient;

    return _apolloClient;
};

export function useApollo(dispatch: Dispatch) {
    return useMemo(() => initApollo(null, dispatch), [dispatch]);
}
