import {ApolloProvider} from '@apollo/client';
import CssBaseline from '@mui/material/CssBaseline';
import {ThemeProvider} from '@mui/material/styles';
import App, {AppProps} from 'next/app';
import Head from 'next/head';
import {useEffect, useMemo} from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {CustomFormats, RawIntlProvider, createIntl, createIntlCache} from 'react-intl';
import {Provider} from 'react-redux';
import {persistStore} from 'redux-persist';
import {PersistGate} from 'redux-persist/integration/react';
import {ErrorFallback} from '../client/common/components/ErrorFallback';
import {browserSupoort} from '../client/common/constants';
import {useApollo} from '../client/lib/apolloClient';
import {AppContextProvider} from '../client/lib/context/AppContext';
import store from '../client/lib/store';
import {useTheme} from '../client/lib/theme';
import Czech from '../lang/cs.json';

/* Root stylesheet */
import {EmotionCache} from '@emotion/cache';
import {CacheProvider} from '@emotion/react';
import {AdapterDateFns} from '@mui/x-date-pickers/AdapterDateFns';
import {LocalizationProvider} from '@mui/x-date-pickers/LocalizationProvider';
import cs from 'date-fns/locale/cs';
import {redirect} from '../client/common/utils/CommonUtils';
import createEmotionCache from '../client/lib/createEmotionCache';
import '../public/static/fonts/BrixSans/stylesheet.css';

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createEmotionCache();

// This is optional but highly recommended
// since it prevents memory leak
const intlCache = createIntlCache();

type Props = AppProps & {
    readonly locale: any;
    readonly formats: CustomFormats | undefined;
    readonly statusCode: number;
    readonly emotionCache?: EmotionCache;
};

const MyApp = (props: Props) => {
    const {Component, pageProps, locale, formats, statusCode, emotionCache = clientSideEmotionCache} = props;

    const [shortLocale] = locale ? locale.split('-') : ['en'];

    const messages = useMemo(() => {
        switch (shortLocale) {
            case 'cs':
                return Czech as unknown as Record<string, string>;
            default:
                return Czech as unknown as Record<string, string>;
        }
    }, [shortLocale]);

    const persistor = persistStore(store, {}, () => {
        persistor.persist();
    });

    const {theme} = useTheme();

    const apolloClient = useApollo(store.dispatch);

    useEffect(() => {
        if (!browserSupoort(window.navigator.userAgent)) {
            redirect({pathname: '/warning.html'});
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector('#jss-server-side');
        if (jssStyles?.parentNode) {
            jssStyles.parentNode.removeChild(jssStyles);
        }
    }, []);

    return (
        <Provider store={store}>
            <PersistGate persistor={persistor}>
                <ApolloProvider client={apolloClient}>
                    <RawIntlProvider value={createIntl({locale, messages, formats}, intlCache)}>
                        <CacheProvider value={emotionCache}>
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={cs}>
                                    <CssBaseline />
                                    <ErrorBoundary FallbackComponent={ErrorFallback}>
                                        <Head>
                                            <meta
                                                name="viewport"
                                                content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no, user-scalable=no, viewport-fit=cover"
                                            />
                                            {/* eslint-disable-next-line i18next/no-literal-string */}
                                            <title>Žádost o připojení</title>
                                        </Head>

                                        <AppContextProvider>
                                            <Component {...pageProps} statusCode={statusCode} />
                                        </AppContextProvider>
                                    </ErrorBoundary>
                                </LocalizationProvider>
                            </ThemeProvider>
                        </CacheProvider>
                    </RawIntlProvider>
                </ApolloProvider>
            </PersistGate>
        </Provider>
    );
};

const getInitialProps: typeof App.getInitialProps = async (appContext) => {
    const {ctx} = appContext;

    const [appProps] = await Promise.all([App.getInitialProps(appContext)]);

    const formats = getFormats();

    const statusCode = appContext?.ctx?.res?.statusCode;

    return {
        ...appProps,
        locale: getLocales(ctx?.req?.headers['accept-language']),
        formats,
        statusCode,
    };
};

const getLocales = (str: string | undefined) => {
    return str?.split(',').map((type) => type.split(';')[0]?.trim().replace('*', ''))[0] ?? 'cs';
};

const getFormats = () => ({
    number: {
        CS: {
            style: 'currency',
            currency: 'CZK',
        },
        EN: {
            style: 'currency',
            currency: 'US',
        },
    },
});

MyApp.getInitialProps = getInitialProps;

export default MyApp;
