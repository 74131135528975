import getConfig from 'next/config';

const {publicRuntimeConfig} = getConfig(); // Detekce ruznych nepovolenych bilych znaku
export const whiteSpace = new RegExp(/[  \u00A0\u1680\u180E\u2000-\u200B\u202F\u205F\u3000\uFEFF]/g);

// Specifikace nepodporovaných browserů
export const browserSupoort = (userAgent: string) => {
    const UA = userAgent.toLowerCase() ?? '';
    // const isIE = UA && /; msie|trident/i.test(UA);
    const isEdge = UA && /edg/i.test(UA);
    const isAndroid = UA && UA.indexOf('android') > 0;
    const isIOS = UA && /iphone|ipad|ipod|ios/i.test(UA);
    const isChrome = UA && /chrome|crios/i.test(UA) && !/opr|opera|chromium|edg|ucbrowser|googlebot|presto/i.test(UA);
    // const isGoogleBot = UA && /googlebot/i.test(UA);
    const isChromium = UA && /chromium/i.test(UA);
    // const isUcBrowser = UA && /ucbrowser/i.test(UA);
    const isSafari = UA && /safari/i.test(UA) && !/chromium|edg|ucbrowser|chrome|crios|opr|opera|fxios|firefox|presto/i.test(UA);
    const isFirefox = UA && /firefox|fxios/i.test(UA) && !/seamonkey/i.test(UA);
    // const isOpera = UA && /opr|opera/i.test(UA);
    const isMobile = /\b(BlackBerry|webOS|iPhone|IEMobile)\b/i.test(UA) || /\b(Android|Windows Phone|iPad|iPod)\b/i.test(UA);
    // const isSamsung = UA && /samsungbrowser/i.test(UA);
    const isIPad = UA && /ipad/.test(UA);
    const isIPhone = UA && /iphone/.test(UA);
    const isIPod = UA && /ipod/.test(UA);
    const isWebView = userAgent.includes('wv');
    const Distribuce24App = userAgent.includes('Distribuce24App');
    const isMac = UA && /macintosh|mac os x/i.test(UA);

    return (
        isEdge ||
        isAndroid ||
        isIOS ||
        isIPad ||
        isIPhone ||
        isIPod ||
        isChrome ||
        isChromium ||
        isSafari ||
        isFirefox ||
        isMobile ||
        isWebView ||
        Distribuce24App ||
        isMac
    );
};

// mapa formulářových bloků na createSafeConsumer
export const formMapPath = {
    ROZCESTNIK: '/rozcestnik',
    OSOBNI_UDAJE_ZAKAZNIKA: '/osobniUdaje',
    SPECIFIKACE_UZEMI: '/specifikaceUzemi',
    GEOPORTAL: '/geoportal',
    TECHNICKA_SPECIFIKACE_ODBERU: '/technickaSpecifikaceOdberu',
    UPRESNENI_PREDCHOZIHO_ODBERU: '/upresneniPredchozihoOdberu',
    TECHNICKE_SPECIFIKACE_VYROBNY: '/technickaSpecifikaceVyrobny',
    SOUHRN_ZADOSTI: '/souhrnZadosti',
    PODEKOVANI: '/podekovani',
};

export const redirectURI = publicRuntimeConfig.SELFCARE_FRONTEND_URL;

// minimální velikost souboru
export const MIN_FILE_B_SIZE = 10;

// maximální velikost souboru
export const MAX_FILE_MB_SIZE = 5;

// maximální velikost souboru
export const MAX_FILE_B_SIZE = MAX_FILE_MB_SIZE * 1024 ** 2;

export const FileAllowedTypeImport = {XLS: 'xls', XLSX: 'xlsx'} as const;

// seznam spotřebičů pro elektřinu
export const ZadostElektrinaSopSpotrebicTyp = {
    /** Dobíjecí stanice - elektromobil */
    DOBIJECI_STANICE_ELEKTROMOBIL: 'DOBIJECI_STANICE_ELEKTROMOBIL',
    /** Elektrické vaření - 3 fázové */
    ELEKTRICE_VARENI: 'ELEKTRICE_VARENI',
    /** Elektrické vytápění - akumulační */
    ELEKTRICKE_VYTAPENI_AKUMULACNI: 'ELEKTRICKE_VYTAPENI_AKUMULACNI',
    /** Elektrické vytápění - přímotopné */
    ELEKTRICE_VYTAPENI_PRIMOTOPNE: 'ELEKTRICE_VYTAPENI_PRIMOTOPNE',
    /** Klimatizace */
    KLIMATIZACE: 'KLIMATIZACE',
    /** Motory, pohony, svářečky apod. */
    MOTORY_POHONY_SVARECKY_APOD: 'MOTORY_POHONY_SVARECKY_APOD',
    /** Ohřev vody - akumulační */
    OHREV_VODY_AKUMULACNI: 'OHREV_VODY_AKUMULACNI',
    /** Ohřev vody - přímotopný */
    OHREV_VODY_PRIMOTOPNY: 'OHREV_VODY_PRIMOTOPNY',
    /** Ostatní spotřebiče nad 5 kW */
    OSTATNI_SPOTREBICE: 'OSTATNI_SPOTREBICE',
    /** Osvětlení */
    OSVETLENI: 'OSVETLENI',
    /** Standardní spotřebiče do 16 A (do 5 kW) */
    STANDARDNI_SPOTREBICE: 'STANDARDNI_SPOTREBICE',
    /** Tepelné čerpadlo */
    TEPELNE_CERPADLO: 'TEPELNE_CERPADLO',
} as const;

export type ZadostElektrinaSopSpotrebicTyp = (typeof ZadostElektrinaSopSpotrebicTyp)[keyof typeof ZadostElektrinaSopSpotrebicTyp];
export const ZadostPlynSopSpotrebicTyp = {
    BOILER: 'BOILER',
    CNG_STANICE: 'CNG_STANICE',
    KONDENZACNI_KOTEL: 'KONDENZACNI_KOTEL',
    KOTEL: 'KOTEL',
    PRUTOKOVY_OHRIVAC_VODY: 'PRUTOKOVY_OHRIVAC_VODY',
    SPORAK: 'SPORAK',
    TOPIDLO: 'TOPIDLO',
    OSTATNI_SPOTREBICE: 'OSTATNI_SPOTREBICE',
} as const;

export type ZadostPlynSopSpotrebicTyp = (typeof ZadostPlynSopSpotrebicTyp)[keyof typeof ZadostPlynSopSpotrebicTyp];

// odkazy
export const souhrnZadostiOdkaz = 'https://www.egd.cz';
export const podekovaniZadostiOdkaz = 'https://www.egd.cz/sledovani-stavu-zadosti';
export const vypocetnakladuOdkaz = 'https://www.egd.cz/vypocet-podilu-zadatele-na-opravnenych-nakladech';
export const celkovaPredpokladanaSpotrebaOdkaz = 'https://www.egd.cz/formulare-zadosti-pozadavku-k-plynu';
export const zmenaOdberuNotificationOdkaz = 'https://egd.cz/formulare-zadosti-pozadavku-k-elektrine';
export const pozadovanaSazbaOdkaz = 'https://www.egd.cz/cenik-sluzeb-sazeb-elektriny';
export const zmenaOdberuOdkaz = 'https://www.egd.cz/zmena-odberatele-nebo-dodavatele-elektriny';
export const faq = 'https://www.egd.cz/vyrobny-casto-kladene-otazky';

export const titulyPredJmenem = [
    '--bez titulu--',
    'Bc.',
    'BcA.',
    'MgA.',
    'Ing.arch.',
    'MUDr.',
    'MVDr.',
    'Ing.',
    'PhDr.',
    'Mgr.',
    'JUDr.',
    'RNDr.',
    'PharmDr.',
    'ThLic.',
    'ThDr.',
    'Dr.',
    'PaedDr.',
    'PhMr.',
    'akad.',
    'akad.arch.',
    'akad.malíř',
    'akad.sochař',
    'prof.',
    'doc.',
    'RSDr.',
    'Dipl.Ing.',
    'Ing. chem.',
    'RTDr.',
    'Ph.Lic.',
    'Dipl. Um.',
    'Dipl. KFM',
    'Dipl. ekonom',
    'Th.Dipl.',
    'MDDr.',
    'Dr. techn.',
    'Ing. et Ing.',
];

export const titulyZaJmenem = ['--bez titulu--', 'Ph.D.', 'CSc.', 'DrSc.', 'MSc', 'MBA', 'DiS.', 'BA', 'BBS', 'Th.Dipl.', 'LL.M.', 'Th.D.'];

// státy
export const stat = [
    {
        klic: 'AD',
        oznaceni: 'Andorra',
        narodnost: 'andorský',
        dlouheOznaceni: 'Andorra',
    },
    {
        klic: 'AE',
        oznaceni: 'Sp.arab.emiráty',
        narodnost: 'Spoj.arab.emir.',
        dlouheOznaceni: 'Spojené arabské emiráty',
    },
    {
        klic: 'AF',
        oznaceni: 'Afganistán',
        narodnost: 'afgánský',
        dlouheOznaceni: 'Afganistán',
    },
    {
        klic: 'AG',
        oznaceni: 'Antigua/Barbuda',
        narodnost: 'antiguánský',
        dlouheOznaceni: 'Antigua and Barbuda',
    },
    {
        klic: 'AI',
        oznaceni: 'Anguilla',
        narodnost: 'Anguilla',
        dlouheOznaceni: 'Anguilla',
    },
    {
        klic: 'AL',
        oznaceni: 'Albánie',
        narodnost: 'albánský',
        dlouheOznaceni: 'Albánie',
    },
    {
        klic: 'AM',
        oznaceni: 'Arménie',
        narodnost: 'arménský',
        dlouheOznaceni: 'Arménie',
    },
    {
        klic: 'AO',
        oznaceni: 'Angola',
        narodnost: 'angolský',
        dlouheOznaceni: 'Angola',
    },
    {
        klic: 'AQ',
        oznaceni: 'Antarktida',
        narodnost: 'Antarktida',
        dlouheOznaceni: 'Antarktida',
    },
    {
        klic: 'AR',
        oznaceni: 'Argentina',
        narodnost: 'argentinský',
        dlouheOznaceni: 'Argentina',
    },
    {
        klic: 'AS',
        oznaceni: 'Americká Samoa',
        narodnost: 'samojský',
        dlouheOznaceni: 'Americká Samoa',
    },
    {
        klic: 'AT',
        oznaceni: 'Rakousko',
        narodnost: 'rakouský',
        dlouheOznaceni: 'Rakousko',
    },
    {
        klic: 'AU',
        oznaceni: 'Austrálie',
        narodnost: 'australský',
        dlouheOznaceni: 'Austrálie',
    },
    {
        klic: 'AW',
        oznaceni: 'Aruba',
        narodnost: 'arubánský',
        dlouheOznaceni: 'Aruba',
    },
    {
        klic: 'AZ',
        oznaceni: 'Azerbajdžán',
        narodnost: 'azerbájdžánský',
        dlouheOznaceni: 'Azerbajdžán',
    },
    {
        klic: 'BA',
        oznaceni: 'Bosna-Herceg.',
        narodnost: 'bosenský',
        dlouheOznaceni: 'Bosna a Hercegovina',
    },
    {
        klic: 'BB',
        oznaceni: 'Barbados',
        narodnost: 'barbadoský',
        dlouheOznaceni: 'Barbados',
    },
    {
        klic: 'BD',
        oznaceni: 'Bangladéš',
        narodnost: 'bangladéšský',
        dlouheOznaceni: 'Bangladéš',
    },
    {
        klic: 'BE',
        oznaceni: 'Belgie',
        narodnost: 'belgický',
        dlouheOznaceni: 'Belgie',
    },
    {
        klic: 'BF',
        oznaceni: 'Burkina Faso',
        narodnost: 'Burkina-Faso',
        dlouheOznaceni: 'Burkina Faso',
    },
    {
        klic: 'BG',
        oznaceni: 'Bulharsko',
        narodnost: 'bulharský',
        dlouheOznaceni: 'Bulharsko',
    },
    {
        klic: 'BH',
        oznaceni: 'Bahrain',
        narodnost: 'bahrainský',
        dlouheOznaceni: 'Bahrain',
    },
    {
        klic: 'BI',
        oznaceni: 'Burundi',
        narodnost: 'burundský',
        dlouheOznaceni: 'Burundi',
    },
    {
        klic: 'BJ',
        oznaceni: 'Benin',
        narodnost: 'beninský',
        dlouheOznaceni: 'Benin',
    },
    {
        klic: 'BL',
        oznaceni: 'Modrá',
        dlouheOznaceni: 'Modrá',
    },
    {
        klic: 'BM',
        oznaceni: 'Bermudy',
        narodnost: 'bermudský',
        dlouheOznaceni: 'Bermudy',
    },
    {
        klic: 'BN',
        oznaceni: 'Brunei Drussal.',
        narodnost: 'brunejský',
        dlouheOznaceni: 'Brunei Darussalam',
    },
    {
        klic: 'BO',
        oznaceni: 'Bolívie',
        narodnost: 'bolívijský',
        dlouheOznaceni: 'Bolívie',
    },
    {
        klic: 'BQ',
        oznaceni: 'Bonaire, Saba',
        narodnost: 'nizozemský',
        dlouheOznaceni: 'Bonaire, Saint Eustatius a Saba',
    },
    {
        klic: 'BR',
        oznaceni: 'Brazílie',
        narodnost: 'brazilský',
        dlouheOznaceni: 'Brazílie',
    },
    {
        klic: 'BS',
        oznaceni: 'Bahamy',
        narodnost: 'bahamský',
        dlouheOznaceni: 'Bahamy',
    },
    {
        klic: 'BT',
        oznaceni: 'Bhútán',
        narodnost: 'bhútánský',
        dlouheOznaceni: 'Bhútán',
    },
    {
        klic: 'BV',
        oznaceni: 'Bouvet Islands',
        narodnost: 'Bouvet Islands',
        dlouheOznaceni: 'Bouvet Islands',
    },
    {
        klic: 'BW',
        oznaceni: 'Botswana',
        narodnost: 'botswanský',
        dlouheOznaceni: 'Botswana',
    },
    {
        klic: 'BY',
        oznaceni: 'Bělorusko',
        narodnost: 'běloruský',
        dlouheOznaceni: 'Bělorusko',
    },
    {
        klic: 'BZ',
        oznaceni: 'Belize',
        narodnost: 'belizký',
        dlouheOznaceni: 'Belize',
    },
    {
        klic: 'CA',
        oznaceni: 'Kanada',
        narodnost: 'kanadský',
        dlouheOznaceni: 'Kanada',
    },
    {
        klic: 'CC',
        oznaceni: 'Kokos.ostrovy',
        narodnost: 'australský',
        dlouheOznaceni: 'Kokosové ostrovy',
    },
    {
        klic: 'CD',
        oznaceni: 'Republika Kongo',
        narodnost: 'konžský',
        dlouheOznaceni: 'Republika Kongo',
    },
    {
        klic: 'CF',
        oznaceni: 'Středoafr.rep.',
        narodnost: 'středoafrický',
        dlouheOznaceni: 'Středoafrická republika',
    },
    {
        klic: 'CG',
        oznaceni: 'Kongo',
        narodnost: 'konžský',
        dlouheOznaceni: 'Kongo',
    },
    {
        klic: 'CH',
        oznaceni: 'Švýcarsko',
        narodnost: 'švýcarský',
        dlouheOznaceni: 'Švýcarsko',
    },
    {
        klic: 'CI',
        oznaceni: 'Pobřeží slonov.',
        narodnost: 'ivorský',
        dlouheOznaceni: 'Pobřeží slonoviny',
    },
    {
        klic: 'CK',
        oznaceni: 'Cookovy ostrovy',
        narodnost: 'Cookovy ostrovy',
        dlouheOznaceni: 'Cookovy ostrovy',
    },
    {
        klic: 'CL',
        oznaceni: 'Chile',
        narodnost: 'chilský',
        dlouheOznaceni: 'Chile',
    },
    {
        klic: 'CM',
        oznaceni: 'Kamerun',
        narodnost: 'kamerunský',
        dlouheOznaceni: 'Kamerun',
    },
    {
        klic: 'CN',
        oznaceni: 'Čína',
        narodnost: 'čínský',
        dlouheOznaceni: 'Čína',
    },
    {
        klic: 'CO',
        oznaceni: 'Kolumbie',
        narodnost: 'kolumbijský',
        dlouheOznaceni: 'Kolumbie',
    },
    {
        klic: 'CR',
        oznaceni: 'Kostarika',
        narodnost: 'kostarický',
        dlouheOznaceni: 'Kostarika',
    },
    {
        klic: 'CU',
        oznaceni: 'Kuba',
        narodnost: 'kubánský',
        dlouheOznaceni: 'Kuba',
    },
    {
        klic: 'CV',
        oznaceni: 'Kapverdy',
        narodnost: 'kapverdský',
        dlouheOznaceni: 'Kapverdy',
    },
    {
        klic: 'CW',
        oznaceni: 'Curaçao',
        narodnost: 'curaçaoský',
        dlouheOznaceni: 'Curaçao',
    },
    {
        klic: 'CX',
        oznaceni: 'Vánoční ostrovy',
        narodnost: 'australský',
        dlouheOznaceni: 'Vánoční ostrovy',
    },
    {
        klic: 'CY',
        oznaceni: 'Kypr',
        narodnost: 'kyperský',
        dlouheOznaceni: 'Kypr',
    },
    {
        klic: 'CZ',
        oznaceni: 'Česká republika',
        narodnost: 'český',
        dlouheOznaceni: 'Česká republika',
    },
    {
        klic: 'DE',
        oznaceni: 'Německo',
        narodnost: 'německý',
        dlouheOznaceni: 'Německo',
    },
    {
        klic: 'DJ',
        oznaceni: 'Džibuti',
        narodnost: 'džibutský',
        dlouheOznaceni: 'Džibuti',
    },
    {
        klic: 'DK',
        oznaceni: 'Dánsko',
        narodnost: 'dánský',
        dlouheOznaceni: 'Dánsko',
    },
    {
        klic: 'DM',
        oznaceni: 'Dominika',
        narodnost: 'dominický',
        dlouheOznaceni: 'Dominika',
    },
    {
        klic: 'DO',
        oznaceni: 'Dominikán.rep.',
        narodnost: 'dominikánský',
        dlouheOznaceni: 'Dominikánská republika',
    },
    {
        klic: 'DZ',
        oznaceni: 'Alžírsko',
        narodnost: 'alžírský',
        dlouheOznaceni: 'Alžírsko',
    },
    {
        klic: 'EC',
        oznaceni: 'Ekvádor',
        narodnost: 'ekvádorský',
        dlouheOznaceni: 'Ekvádor',
    },
    {
        klic: 'EE',
        oznaceni: 'Estonsko',
        narodnost: 'estonský',
        dlouheOznaceni: 'Estonsko',
    },
    {
        klic: 'EG',
        oznaceni: 'Egypt',
        narodnost: 'egyptský',
        dlouheOznaceni: 'Egypt',
    },
    {
        klic: 'EH',
        oznaceni: 'Záp.Sahara',
        narodnost: 'francouzský',
        dlouheOznaceni: 'Západní Sahara',
    },
    {
        klic: 'ER',
        oznaceni: 'Eritrea',
        narodnost: 'eritrejský',
        dlouheOznaceni: 'Eritrea',
    },
    {
        klic: 'ES',
        oznaceni: 'Španělsko',
        narodnost: 'Španělština',
        dlouheOznaceni: 'Španělsko',
    },
    {
        klic: 'ET',
        oznaceni: 'Etiopie',
        narodnost: 'etiopský',
        dlouheOznaceni: 'Etiopie',
    },
    {
        klic: 'EU',
        oznaceni: 'Evropská unie',
        dlouheOznaceni: 'Evropská unie',
    },
    {
        klic: 'FI',
        oznaceni: 'Finsko',
        narodnost: 'finský',
        dlouheOznaceni: 'Finsko',
    },
    {
        klic: 'FJ',
        oznaceni: 'Fidži',
        narodnost: 'fidžský',
        dlouheOznaceni: 'Fidži',
    },
    {
        klic: 'FK',
        oznaceni: 'Falkland. ostr.',
        narodnost: 'britský',
        dlouheOznaceni: 'Falklandy',
    },
    {
        klic: 'FM',
        oznaceni: 'Mikronésie',
        narodnost: 'mikronéský',
        dlouheOznaceni: 'Mikronésie',
    },
    {
        klic: 'FO',
        oznaceni: 'Faerské ostr.',
        narodnost: 'dánský',
        dlouheOznaceni: 'Faerské ostrovy',
    },
    {
        klic: 'FR',
        oznaceni: 'Francie',
        narodnost: 'francouzský',
        dlouheOznaceni: 'Francie',
    },
    {
        klic: 'GA',
        oznaceni: 'Gabon',
        narodnost: 'gabonský',
        dlouheOznaceni: 'Gabon',
    },
    {
        klic: 'GB',
        oznaceni: 'Spoj.království',
        narodnost: 'britský',
        dlouheOznaceni: 'Spojené království Velké Británie a Sever.Irska',
    },
    {
        klic: 'GD',
        oznaceni: 'Grenada',
        narodnost: 'grenadský',
        dlouheOznaceni: 'Grenada',
    },
    {
        klic: 'GE',
        oznaceni: 'Gruzie',
        narodnost: 'gruzínský',
        dlouheOznaceni: 'Gruzie',
    },
    {
        klic: 'GF',
        oznaceni: 'Franc.Guayana',
        narodnost: 'francouzský',
        dlouheOznaceni: 'Francouzská Guayana',
    },
    {
        klic: 'GG',
        oznaceni: 'Guernsey',
        narodnost: 'britský',
        dlouheOznaceni: 'Guernsey (Normandské ostrovy)',
    },
    {
        klic: 'GH',
        oznaceni: 'Ghana',
        narodnost: 'ghanský',
        dlouheOznaceni: 'Ghana',
    },
    {
        klic: 'GI',
        oznaceni: 'Gibraltar',
        narodnost: 'Gibraltar',
        dlouheOznaceni: 'Gibraltar',
    },
    {
        klic: 'GL',
        oznaceni: 'Grónsko',
        narodnost: 'dánský',
        dlouheOznaceni: 'Grónsko',
    },
    {
        klic: 'GM',
        oznaceni: 'Gambie',
        narodnost: 'gambijský',
        dlouheOznaceni: 'Gambie',
    },
    {
        klic: 'GN',
        oznaceni: 'Guinea',
        narodnost: 'guinejský',
        dlouheOznaceni: 'Guinea',
    },
    {
        klic: 'GP',
        oznaceni: 'Guadeloupe',
        narodnost: 'francouzský',
        dlouheOznaceni: 'Guadeloupe',
    },
    {
        klic: 'GQ',
        oznaceni: 'Rovník.Guinea',
        narodnost: 'rovník.guinej.',
        dlouheOznaceni: 'Rovníková Guinea',
    },
    {
        klic: 'GR',
        oznaceni: 'Řecko',
        narodnost: 'řecký',
        dlouheOznaceni: 'Řecko',
    },
    {
        klic: 'GS',
        oznaceni: 'Ost.S.Sandwich',
        narodnost: 'Jižní Arménie',
        dlouheOznaceni: 'South Georgia a South Sandwich Islands',
    },
    {
        klic: 'GT',
        oznaceni: 'Guatemala',
        narodnost: 'guatemalský',
        dlouheOznaceni: 'Guatemala',
    },
    {
        klic: 'GU',
        oznaceni: 'Guam',
        narodnost: 'americký',
        dlouheOznaceni: 'Guam',
    },
    {
        klic: 'GW',
        oznaceni: 'Guinea-Bissau',
        narodnost: 'Guinea-Bissau',
        dlouheOznaceni: 'Guinejsko-bissauská republika',
    },
    {
        klic: 'GY',
        oznaceni: 'Guayana',
        narodnost: 'guayanský',
        dlouheOznaceni: 'Guayana',
    },
    {
        klic: 'HK',
        oznaceni: 'Hongkong',
        narodnost: 'Hongkong',
        dlouheOznaceni: 'Hongkong',
    },
    {
        klic: 'HM',
        oznaceni: 'Heard/McDon.ost',
        narodnost: 'Heard/McDon.ost',
        dlouheOznaceni: 'Ostrovy Heard a McDonal',
    },
    {
        klic: 'HN',
        oznaceni: 'Honduras',
        narodnost: 'honduraský',
        dlouheOznaceni: 'Honduras',
    },
    {
        klic: 'HR',
        oznaceni: 'Chorvatsko',
        narodnost: 'chorvatský',
        dlouheOznaceni: 'Chorvatsko',
    },
    {
        klic: 'HT',
        oznaceni: 'Haiti',
        narodnost: 'haitský',
        dlouheOznaceni: 'Haiti',
    },
    {
        klic: 'HU',
        oznaceni: 'Maďarsko',
        narodnost: 'maďarský',
        dlouheOznaceni: 'Maďarsko',
    },
    {
        klic: 'ID',
        oznaceni: 'Indonésie',
        narodnost: 'indonéský',
        dlouheOznaceni: 'Indonésie',
    },
    {
        klic: 'IE',
        oznaceni: 'Irsko',
        narodnost: 'irský',
        dlouheOznaceni: 'Irsko',
    },
    {
        klic: 'IL',
        oznaceni: 'Izrael',
        narodnost: 'izraelský',
        dlouheOznaceni: 'Izrael',
    },
    {
        klic: 'IM',
        oznaceni: 'Ostrov Man',
        narodnost: 'britský',
        dlouheOznaceni: 'Ostrov Man',
    },
    {
        klic: 'IN',
        oznaceni: 'Indie',
        narodnost: 'indický',
        dlouheOznaceni: 'Indie',
    },
    {
        klic: 'IO',
        oznaceni: 'Brit obl.vIndOc',
        narodnost: 'Brit obl.vIndOc',
        dlouheOznaceni: 'Britská oblast v Indickém oceánu',
    },
    {
        klic: 'IQ',
        oznaceni: 'Irák',
        narodnost: 'irácký',
        dlouheOznaceni: 'Irák',
    },
    {
        klic: 'IR',
        oznaceni: 'Írán',
        narodnost: 'iránský',
        dlouheOznaceni: 'Írán',
    },
    {
        klic: 'IS',
        oznaceni: 'Island',
        narodnost: 'islandský',
        dlouheOznaceni: 'Island',
    },
    {
        klic: 'IT',
        oznaceni: 'Itálie',
        narodnost: 'Italština',
        dlouheOznaceni: 'Itálie',
    },
    {
        klic: 'JE',
        oznaceni: 'Jersey',
        narodnost: 'britský',
        dlouheOznaceni: 'Jersey',
    },
    {
        klic: 'JM',
        oznaceni: 'Jamajka',
        narodnost: 'jamajský',
        dlouheOznaceni: 'Jamajka',
    },
    {
        klic: 'JO',
        oznaceni: 'Jordánsko',
        narodnost: 'jordánský',
        dlouheOznaceni: 'Jordánsko',
    },
    {
        klic: 'JP',
        oznaceni: 'Japonsko',
        narodnost: 'japonský',
        dlouheOznaceni: 'Japonsko',
    },
    {
        klic: 'KE',
        oznaceni: 'Keňa',
        narodnost: 'keňský',
        dlouheOznaceni: 'Keňa',
    },
    {
        klic: 'KG',
        oznaceni: 'Kyrgyzstán',
        narodnost: 'kyrgyzský',
        dlouheOznaceni: 'Kyrgyzstán',
    },
    {
        klic: 'KH',
        oznaceni: 'Kambodža',
        narodnost: 'kambodžský',
        dlouheOznaceni: 'Kambodža',
    },
    {
        klic: 'KI',
        oznaceni: 'Kiribati',
        narodnost: 'kiribatský',
        dlouheOznaceni: 'Kiribati',
    },
    {
        klic: 'KM',
        oznaceni: 'Komory',
        narodnost: 'komorský',
        dlouheOznaceni: 'Komory',
    },
    {
        klic: 'KN',
        oznaceni: 'St.Kryšt.&Nevis',
        narodnost: 'ze St.Kryštofa&',
        dlouheOznaceni: 'Federace Svatý Kryštof a Nevis',
    },
    {
        klic: 'KP',
        oznaceni: 'Severní Korea',
        narodnost: 'korejský',
        dlouheOznaceni: 'Severní Korea',
    },
    {
        klic: 'KR',
        oznaceni: 'Jižní Korea',
        narodnost: 'korejský',
        dlouheOznaceni: 'Jižní Korea',
    },
    {
        klic: 'KW',
        oznaceni: 'Kuvajt',
        narodnost: 'kuvajtský',
        dlouheOznaceni: 'Kuvajt',
    },
    {
        klic: 'KY',
        oznaceni: 'Kajmanské ostr.',
        narodnost: 'britský',
        dlouheOznaceni: 'Kajmanské ostrovy',
    },
    {
        klic: 'KZ',
        oznaceni: 'Kazachstán',
        narodnost: 'kazašský',
        dlouheOznaceni: 'Kazachstán',
    },
    {
        klic: 'LA',
        oznaceni: 'Laos',
        narodnost: 'laoský',
        dlouheOznaceni: 'Laos',
    },
    {
        klic: 'LB',
        oznaceni: 'Libanon',
        narodnost: 'libanonský',
        dlouheOznaceni: 'Libanon',
    },
    {
        klic: 'LC',
        oznaceni: 'Svatá Lucie',
        narodnost: 'svatolucijský',
        dlouheOznaceni: 'Svatá Lucie',
    },
    {
        klic: 'LI',
        oznaceni: 'Lichtenštejnsko',
        narodnost: 'lichtenštejnský',
        dlouheOznaceni: 'Lichtenštejnsko',
    },
    {
        klic: 'LK',
        oznaceni: 'Srí Lanka',
        narodnost: 'srílanský',
        dlouheOznaceni: 'Srí Lanka',
    },
    {
        klic: 'LR',
        oznaceni: 'Libérie',
        narodnost: 'liberijský',
        dlouheOznaceni: 'Libérie',
    },
    {
        klic: 'LS',
        oznaceni: 'Lesotho',
        narodnost: 'lesotský',
        dlouheOznaceni: 'Lesotho',
    },
    {
        klic: 'LT',
        oznaceni: 'Litva',
        narodnost: 'litevský',
        dlouheOznaceni: 'Litva',
    },
    {
        klic: 'LU',
        oznaceni: 'Lucembursko',
        narodnost: 'lucemburský',
        dlouheOznaceni: 'Lucembursko',
    },
    {
        klic: 'LV',
        oznaceni: 'Lotyšsko',
        narodnost: 'lotyšský',
        dlouheOznaceni: 'Lotyšsko',
    },
    {
        klic: 'LY',
        oznaceni: 'Libye',
        narodnost: 'libyjský',
        dlouheOznaceni: 'Libye',
    },
    {
        klic: 'MA',
        oznaceni: 'Maroko',
        narodnost: 'marocký',
        dlouheOznaceni: 'Maroko',
    },
    {
        klic: 'MC',
        oznaceni: 'Monako',
        narodnost: 'monacký',
        dlouheOznaceni: 'Monako',
    },
    {
        klic: 'MD',
        oznaceni: 'Moldavsko',
        narodnost: 'moldavský',
        dlouheOznaceni: 'Moldavsko',
    },
    {
        klic: 'ME',
        oznaceni: 'Černá Hora',
        narodnost: 'moldavský',
        dlouheOznaceni: 'Černá Hora',
    },
    {
        klic: 'MG',
        oznaceni: 'Madagaskar',
        narodnost: 'malgašský',
        dlouheOznaceni: 'Madagaskar',
    },
    {
        klic: 'MH',
        oznaceni: 'Marshall.ostr.',
        narodnost: 'Marshall.ostr.',
        dlouheOznaceni: 'Marshall.ostr.',
    },
    {
        klic: 'MK',
        oznaceni: 'Makedonie',
        narodnost: 'makedonský',
        dlouheOznaceni: 'Makedonie',
    },
    {
        klic: 'ML',
        oznaceni: 'Mali',
        narodnost: 'malijský',
        dlouheOznaceni: 'Mali',
    },
    {
        klic: 'MM',
        oznaceni: 'Myanmar',
        narodnost: 'myanmarský',
        dlouheOznaceni: 'Myanmar',
    },
    {
        klic: 'MN',
        oznaceni: 'Mongolsko',
        narodnost: 'mongolský',
        dlouheOznaceni: 'Mongolsko',
    },
    {
        klic: 'MO',
        oznaceni: 'Macao',
        narodnost: 'portugalský',
        dlouheOznaceni: 'Macao',
    },
    {
        klic: 'MP',
        oznaceni: 'Sev.marián.os.',
        narodnost: 'mariánský',
        dlouheOznaceni: 'Severní Mariánské ostrovy',
    },
    {
        klic: 'MQ',
        oznaceni: 'Martinique',
        narodnost: 'francouzský',
        dlouheOznaceni: 'Martinique',
    },
    {
        klic: 'MR',
        oznaceni: 'Mauritánie',
        narodnost: 'mauritánský',
        dlouheOznaceni: 'Mauritánie',
    },
    {
        klic: 'MS',
        oznaceni: 'Montserrat',
        narodnost: 'Montserrat',
        dlouheOznaceni: 'Montserrat',
    },
    {
        klic: 'MT',
        oznaceni: 'Malta',
        narodnost: 'maltský',
        dlouheOznaceni: 'Malta',
    },
    {
        klic: 'MU',
        oznaceni: 'Mauricius',
        narodnost: 'mauricijský',
        dlouheOznaceni: 'Mauricius',
    },
    {
        klic: 'MV',
        oznaceni: 'Maledivy',
        narodnost: 'maledivský',
        dlouheOznaceni: 'Maledivy',
    },
    {
        klic: 'MW',
        oznaceni: 'Malawi',
        narodnost: 'malavijský',
        dlouheOznaceni: 'Malawi',
    },
    {
        klic: 'MX',
        oznaceni: 'Mexiko',
        narodnost: 'mexický',
        dlouheOznaceni: 'Mexiko',
    },
    {
        klic: 'MY',
        oznaceni: 'Malajsie',
        narodnost: 'malajský',
        dlouheOznaceni: 'Malajsie',
    },
    {
        klic: 'MZ',
        oznaceni: 'Mosambik',
        narodnost: 'mozambický',
        dlouheOznaceni: 'Mosambik',
    },
    {
        klic: 'NA',
        oznaceni: 'Namibie',
        narodnost: 'namibijský',
        dlouheOznaceni: 'Namibie',
    },
    {
        klic: 'NC',
        oznaceni: 'Nová Kaledonie',
        narodnost: 'Francouzština',
        dlouheOznaceni: 'Nová Kaledonie',
    },
    {
        klic: 'NE',
        oznaceni: 'Niger',
        narodnost: 'nigerijský',
        dlouheOznaceni: 'Niger',
    },
    {
        klic: 'NF',
        oznaceni: 'Norfolk.ostrovy',
        narodnost: 'Norfolk.ostrovy',
        dlouheOznaceni: 'Norfolk.ostrovy',
    },
    {
        klic: 'NG',
        oznaceni: 'Nigérie',
        narodnost: 'nigerijský',
        dlouheOznaceni: 'Nigérie',
    },
    {
        klic: 'NI',
        oznaceni: 'Nikaragua',
        narodnost: 'nikaragujský',
        dlouheOznaceni: 'Nikaragua',
    },
    {
        klic: 'NL',
        oznaceni: 'Nizozemí',
        narodnost: 'nizozemský',
        dlouheOznaceni: 'Nizozemí',
    },
    {
        klic: 'NO',
        oznaceni: 'Norsko',
        narodnost: 'norský',
        dlouheOznaceni: 'Norsko',
    },
    {
        klic: 'NP',
        oznaceni: 'Nepál',
        narodnost: 'nepálský',
        dlouheOznaceni: 'Nepál',
    },
    {
        klic: 'NR',
        oznaceni: 'Nauru',
        narodnost: 'naurijský',
        dlouheOznaceni: 'Nauru',
    },
    {
        klic: 'NT',
        oznaceni: 'NATO',
        dlouheOznaceni: 'NATO',
    },
    {
        klic: 'NU',
        oznaceni: 'Niue',
        narodnost: 'Niue',
        dlouheOznaceni: 'Niue',
    },
    {
        klic: 'NZ',
        oznaceni: 'Nový Zéland',
        narodnost: 'novozélandský',
        dlouheOznaceni: 'Nový Zéland',
    },
    {
        klic: 'OM',
        oznaceni: 'Omán',
        narodnost: 'ománský',
        dlouheOznaceni: 'Omán',
    },
    {
        klic: 'PA',
        oznaceni: 'Panama',
        narodnost: 'panamský',
        dlouheOznaceni: 'Panama',
    },
    {
        klic: 'PE',
        oznaceni: 'Peru',
        narodnost: 'peruánský',
        dlouheOznaceni: 'Peru',
    },
    {
        klic: 'PF',
        oznaceni: 'Franc.Polynésie',
        narodnost: 'francouzský',
        dlouheOznaceni: 'Fraucouzská Polynézie',
    },
    {
        klic: 'PG',
        oznaceni: 'Papua-NovGuinea',
        narodnost: 'papua-novoguin.',
        dlouheOznaceni: 'Papua-NovGuinea',
    },
    {
        klic: 'PH',
        oznaceni: 'Filipíny',
        narodnost: 'filipínský',
        dlouheOznaceni: 'Filipíny',
    },
    {
        klic: 'PK',
        oznaceni: 'Pakistán',
        narodnost: 'pakistánský',
        dlouheOznaceni: 'Pakistán',
    },
    {
        klic: 'PL',
        oznaceni: 'Polsko',
        narodnost: 'polský',
        dlouheOznaceni: 'Polsko',
    },
    {
        klic: 'PM',
        oznaceni: 'StPier.,Miquel.',
        narodnost: 'francouzský',
        dlouheOznaceni: 'St. Pierre and Miquelon',
    },
    {
        klic: 'PN',
        oznaceni: 'Ostr.Pitcairn',
        narodnost: 'britský',
        dlouheOznaceni: 'Ostr.Pitcairn',
    },
    {
        klic: 'PR',
        oznaceni: 'Portoriko',
        narodnost: 'americký',
        dlouheOznaceni: 'Portoriko',
    },
    {
        klic: 'PS',
        oznaceni: 'Palestina',
        narodnost: 'palestinský',
        dlouheOznaceni: 'Palestina',
    },
    {
        klic: 'PT',
        oznaceni: 'Portugalsko',
        narodnost: 'portugalský',
        dlouheOznaceni: 'Portugalsko',
    },
    {
        klic: 'PW',
        oznaceni: 'Palau',
        narodnost: 'palauský',
        dlouheOznaceni: 'Palau',
    },
    {
        klic: 'PY',
        oznaceni: 'Paraguay',
        narodnost: 'paraguajský',
        dlouheOznaceni: 'Paraguay',
    },
    {
        klic: 'QA',
        oznaceni: 'Katar',
        narodnost: 'katarský',
        dlouheOznaceni: 'Katar',
    },
    {
        klic: 'RE',
        oznaceni: 'Reunion',
        narodnost: 'francouzský',
        dlouheOznaceni: 'Reunion',
    },
    {
        klic: 'RO',
        oznaceni: 'Rumunsko',
        narodnost: 'rumunský',
        dlouheOznaceni: 'Rumunsko',
    },
    {
        klic: 'RS',
        oznaceni: 'Srbsko',
        narodnost: 'srbsky',
        dlouheOznaceni: 'Srbsko',
    },
    {
        klic: 'RU',
        oznaceni: 'Ruská federace',
        narodnost: 'ruský',
        dlouheOznaceni: 'Ruská federace',
    },
    {
        klic: 'RW',
        oznaceni: 'Rwanda',
        narodnost: 'rwandský',
        dlouheOznaceni: 'Rwanda',
    },
    {
        klic: 'SA',
        oznaceni: 'Saúdská Arábie',
        narodnost: 'saudskoarabský',
        dlouheOznaceni: 'Saúdská Arábie',
    },
    {
        klic: 'SB',
        oznaceni: 'Šalamoun.ostr.',
        narodnost: 'šalamounský',
        dlouheOznaceni: 'Šalamounovy ostrovy',
    },
    {
        klic: 'SC',
        oznaceni: 'Seychely',
        narodnost: 'seychelský',
        dlouheOznaceni: 'Seychely',
    },
    {
        klic: 'SD',
        oznaceni: 'Súdán',
        narodnost: 'súdánský',
        dlouheOznaceni: 'Súdán',
    },
    {
        klic: 'SE',
        oznaceni: 'Švédsko',
        narodnost: 'švédský',
        dlouheOznaceni: 'Švédsko',
    },
    {
        klic: 'SG',
        oznaceni: 'Singapur',
        narodnost: 'singapurský',
        dlouheOznaceni: 'Singapur',
    },
    {
        klic: 'SH',
        oznaceni: 'Sv.Helena',
        narodnost: 'Sv.Helena',
        dlouheOznaceni: 'Sv.Helena',
    },
    {
        klic: 'SI',
        oznaceni: 'Slovinsko',
        narodnost: 'slovinský',
        dlouheOznaceni: 'Slovinsko',
    },
    {
        klic: 'SJ',
        oznaceni: 'Svalbard',
        narodnost: 'norský',
        dlouheOznaceni: 'Svalbard',
    },
    {
        klic: 'SK',
        oznaceni: 'Slovensko',
        narodnost: 'slovenský',
        dlouheOznaceni: 'Slovensko',
    },
    {
        klic: 'SL',
        oznaceni: 'Sierra Leone',
        narodnost: 'sierraleonský',
        dlouheOznaceni: 'Sierra Leone',
    },
    {
        klic: 'SM',
        oznaceni: 'San Marino',
        narodnost: 'sanmarinský',
        dlouheOznaceni: 'San Marino',
    },
    {
        klic: 'SN',
        oznaceni: 'Senegal',
        narodnost: 'senegalský',
        dlouheOznaceni: 'Senegal',
    },
    {
        klic: 'SO',
        oznaceni: 'Somálsko',
        narodnost: 'somálský',
        dlouheOznaceni: 'Somálsko',
    },
    {
        klic: 'SR',
        oznaceni: 'Surinam',
        narodnost: 'surinamský',
        dlouheOznaceni: 'Surinam',
    },
    {
        klic: 'SS',
        oznaceni: 'Jižní Súdán',
        narodnost: 'jihosúdánský',
        dlouheOznaceni: 'Jižní Súdán',
    },
    {
        klic: 'ST',
        oznaceni: 'Sv.Tomáš,Prince',
        narodnost: 'svatotomášský',
        dlouheOznaceni: 'Svatý Tomáš a Princův ostr.',
    },
    {
        klic: 'SV',
        oznaceni: 'Salvador',
        narodnost: 'salvadorský',
        dlouheOznaceni: 'Salvador',
    },
    {
        klic: 'SX',
        oznaceni: 'Sint Maarten',
        narodnost: 'Sint Maarten',
        dlouheOznaceni: 'Sint Maarten (nizozemská část)',
    },
    {
        klic: 'SY',
        oznaceni: 'Sýrie',
        narodnost: 'syrský',
        dlouheOznaceni: 'Sýrie',
    },
    {
        klic: 'SZ',
        oznaceni: 'Svazijsko',
        narodnost: 'svazijský',
        dlouheOznaceni: 'Svazijsko',
    },
    {
        klic: 'TC',
        oznaceni: 'Turks, Caicos',
        narodnost: 'Turks, Caicos',
        dlouheOznaceni: 'Ostrovy Turks a Caicos',
    },
    {
        klic: 'TD',
        oznaceni: 'Čad',
        narodnost: 'čadský',
        dlouheOznaceni: 'Čad',
    },
    {
        klic: 'TF',
        oznaceni: 'French S.Territ',
        narodnost: 'francouzský',
        dlouheOznaceni: 'Francouzská jižní a antarktická území',
    },
    {
        klic: 'TG',
        oznaceni: 'Togo',
        narodnost: 'tožský',
        dlouheOznaceni: 'Togo',
    },
    {
        klic: 'TH',
        oznaceni: 'Thajsko',
        narodnost: 'thajský',
        dlouheOznaceni: 'Thajsko',
    },
    {
        klic: 'TJ',
        oznaceni: 'Tádžikistán',
        narodnost: 'tadžikistánský',
        dlouheOznaceni: 'Tádžikistán',
    },
    {
        klic: 'TK',
        oznaceni: 'Ostrovy Tokelau',
        narodnost: 'Ostrovy Tokelau',
        dlouheOznaceni: 'Ostrovy Tokelau',
    },
    {
        klic: 'TL',
        oznaceni: 'Demokratická republika Východní Timor',
        dlouheOznaceni: 'Demokratická republika Východní Timor',
    },
    {
        klic: 'TM',
        oznaceni: 'Turkmenistán',
        narodnost: 'turkmenský',
        dlouheOznaceni: 'Turkmenistán',
    },
    {
        klic: 'TN',
        oznaceni: 'Tunisko',
        narodnost: 'tuniský',
        dlouheOznaceni: 'Tunisko',
    },
    {
        klic: 'TO',
        oznaceni: 'Tonga',
        narodnost: 'tongský',
        dlouheOznaceni: 'Tonga',
    },
    {
        klic: 'TP',
        oznaceni: 'Vých.Timor',
        narodnost: 'Vých.Timor',
        dlouheOznaceni: 'Východní Timor',
    },
    {
        klic: 'TR',
        oznaceni: 'Turecko',
        narodnost: 'turecký',
        dlouheOznaceni: 'Turecko',
    },
    {
        klic: 'TT',
        oznaceni: 'Trinidad,Tobago',
        narodnost: 'z Trinidadu & T',
        dlouheOznaceni: 'Trinidad a Tobago',
    },
    {
        klic: 'TV',
        oznaceni: 'Tuvalu',
        narodnost: 'tuvalujský',
        dlouheOznaceni: 'Tuvalu',
    },
    {
        klic: 'TW',
        oznaceni: 'Tchaj-wan',
        narodnost: 'tchajwanský',
        dlouheOznaceni: 'Tchaj-wan',
    },
    {
        klic: 'TZ',
        oznaceni: 'Tanzanie',
        narodnost: 'tanzanský',
        dlouheOznaceni: 'Tanzanie',
    },
    {
        klic: 'UA',
        oznaceni: 'Ukrajina',
        narodnost: 'ukrajinský',
        dlouheOznaceni: 'Ukrajina',
    },
    {
        klic: 'UG',
        oznaceni: 'Uganda',
        narodnost: 'ugandský',
        dlouheOznaceni: 'Uganda',
    },
    {
        klic: 'UM',
        oznaceni: 'Ostr.mimo LTSA',
        narodnost: 'Ostr.mimo LTSA',
        dlouheOznaceni: 'Americké ostrovy mimo LTSA',
    },
    {
        klic: 'UN',
        oznaceni: 'Spojené národy',
        dlouheOznaceni: 'Spojené národy',
    },
    {
        klic: 'US',
        oznaceni: 'USA',
        narodnost: 'americký',
        dlouheOznaceni: 'USA',
    },
    {
        klic: 'UY',
        oznaceni: 'Uruguay',
        narodnost: 'uruguayský',
        dlouheOznaceni: 'Uruguay',
    },
    {
        klic: 'UZ',
        oznaceni: 'Uzbekistán',
        narodnost: 'uzbekistánský',
        dlouheOznaceni: 'Uzbekistán',
    },
    {
        klic: 'VA',
        oznaceni: 'Vatikán',
        narodnost: 'Vatikán',
        dlouheOznaceni: 'Vatikán',
    },
    {
        klic: 'VC',
        oznaceni: 'Sv.Vincent',
        narodnost: 'svatovincentský',
        dlouheOznaceni: 'Sv. Vincent a Grenadiny',
    },
    {
        klic: 'VE',
        oznaceni: 'Venezuela',
        narodnost: 'venezuelský',
        dlouheOznaceni: 'Venezuela',
    },
    {
        klic: 'VG',
        oznaceni: 'Brit.Panen.Ost.',
        narodnost: 'britský',
        dlouheOznaceni: 'Britské Panenské ostrovy',
    },
    {
        klic: 'VI',
        oznaceni: 'Amer.Panen.Ost.',
        narodnost: 'americký',
        dlouheOznaceni: 'American Virgin Islands',
    },
    {
        klic: 'VN',
        oznaceni: 'Vietnam',
        narodnost: 'vietnamský',
        dlouheOznaceni: 'Vietnam',
    },
    {
        klic: 'VU',
        oznaceni: 'Vanuatu',
        narodnost: 'vanuatský',
        dlouheOznaceni: 'Vanuatu',
    },
    {
        klic: 'WF',
        oznaceni: 'Wallis,Futuna',
        narodnost: 'Wallis,Futuna',
        dlouheOznaceni: 'Wallis a Futuna',
    },
    {
        klic: 'WS',
        oznaceni: 'Západní Samoa',
        narodnost: 'samojský',
        dlouheOznaceni: 'Západní Samoa',
    },
    {
        klic: 'YE',
        oznaceni: 'Jemen',
        narodnost: 'jemenský',
        dlouheOznaceni: 'Jemen',
    },
    {
        klic: 'YT',
        oznaceni: 'Mayotte',
        narodnost: 'francouzský',
        dlouheOznaceni: 'Mayotte',
    },
    {
        klic: 'ZA',
        oznaceni: 'Jižní Afrika',
        narodnost: 'jihoafrický',
        dlouheOznaceni: 'Jižní Afrika',
    },
    {
        klic: 'ZM',
        oznaceni: 'Zambie',
        narodnost: 'zambijský',
        dlouheOznaceni: 'Zambie',
    },
    {
        klic: 'ZW',
        oznaceni: 'Zimbabwe',
        narodnost: 'zimbabvský',
        dlouheOznaceni: 'Zimbabwe',
    },
];
